import React from "react";
import AppLayoutContainer from "../components/AppLayoutContainer";
import { Route, Routes } from "react-router-dom";
import routes from "../routes_mapper";
import LangchainTextChat from "../langchainTextChat/langchainTextChat";
import OpenAiChat from "../openAiChat/OpenAiChat";
import HTMLDetails from "../htmlDetails/htmlDetails";
import AITrainingFromFileUpload from "../textFromFileUpload/AITrainingFromFileUpload";
import MultipleFileUpload  from "../multipleFileUpload/MultipleFileUpload";
import NotFound from "../NotFound";
import DisplayQueryDetails from "../displayQueryDetails/DisplayQueryDetails";
import DisplayQueryDetailsChat from "../displayQueryDetailsChat/DisplayQueryDetailsChat";
import CustomChatBot from "../customChatBot/CustomChatBot";
import CustomChatBotGo from "../customChatBot/CustomChatBotGo";
import CustomChatBotEnvigo from "../customChatBot/CustomChatBotEnvigo";
import CustomChatBotCitiustech from "../customChatBot/CustomChatBotCitiustech";
import AppHeader from "../components/AppHeader";
import AppSidebar from "../components/AppSidebar";
import BotListing from "../botListing/BotListing";
import WebScrapper from "../webScrapper/WebScrapper";
import LaunchBot from "../launchBot/LaunchBot";
import TrainingBot from "../trainingBot/TrainingBot";
const AppRoutes = () => {
  return (
    <AppLayoutContainer>
      <Routes>
        {/* <Route path={routes.ROOT} element={<AITrainingFromFileUpload />} /> */}
        <Route path={routes.ROOT} element={<BotListing />} />
        <Route path={routes.CHAT} element={<LangchainTextChat />} />
        <Route path={routes.FILES} element={<OpenAiChat />} />
        <Route path={routes.TRAINING} element={<AITrainingFromFileUpload />} />
        <Route path={routes.FILEUPLOAD} element={<MultipleFileUpload />} />
        <Route path={routes.HTMLDETAILS} element={<HTMLDetails />} />
        <Route path={routes.QUERYDETAILS} element={<DisplayQueryDetails />} />
        <Route path={routes.QUERYDETAILSCHAT} element={<DisplayQueryDetailsChat />} />
        <Route path={routes.BotListing} element={<AITrainingFromFileUpload />} />
        <Route path={routes.LaunchBot} element={<LaunchBot />} />
        <Route path={routes.TrainingBot} element={<TrainingBot/>}/>
        <Route path={routes.CUSTOMCHATBOT} element={<CustomChatBot />} />
        <Route path={routes.CUSTOMCHATBOTGO} element={<CustomChatBotGo />} />
        <Route path={routes.CUSTOMCHATBOTENVIGO} element={<CustomChatBotEnvigo />} />
        <Route path={routes.CUSTOMCHATBOTCITIUSTECH} element={<CustomChatBotCitiustech />} />
        <Route path={routes.Test} element={<AITrainingFromFileUpload />} />
        <Route path={routes.WEB_SCRAPPER} element={<WebScrapper />} />
        <Route path={"*"} element={<NotFound />} />
      </Routes>
    </AppLayoutContainer>
  );
};

export default AppRoutes;
