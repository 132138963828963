import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
  Modal,
  Row,
  Table,
  Typography,
  Space,
  Input,
  Select,
  DatePicker,
  Button,
} from "antd";
import {
  EyeOutlined,
  ArrowLeftOutlined,
  DownloadOutlined,
  SearchOutlined,
  FilterOutlined,
  UserOutlined,
  MailOutlined,
  RobotFilled,
} from "@ant-design/icons";
import axios from "axios";
import AppUrl from "../config/appUrl";
import routes from "../routes_mapper";
import { useParams } from "react-router-dom";
import "../scss/DisplayQuery.scss";
import { showNotification } from "../utils/commonFunctions";
import MyTaskFilterDate from "../components/MyTaskFilter";
import constants from "../utils/constants";
import DownloadData from "../downLoadData/DownloadData";
import moment from "moment-timezone";
import {useLocation,useNavigate} from "react-router-dom";
const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { FILTER_TYPE_DATE } = constants;
const defaultFilterType = FILTER_TYPE_DATE?.All?._id;


const ViewModal = ({ view }) => {
  const views = view?.views
  const [visible, setVisible] = useState(false);

  const handleView = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const formattedDate = moment(views.timestamp).format("Do MMM, YYYY, h:mm A");
  return (
    <div>
      <EyeOutlined
        style={{
          marginLeft: "5px",
          fontSize: "18px",
          color: "#2a2a2a",
          cursor: "pointer",
        }}
        onClick={handleView}
      />
      <Modal visible={visible} onCancel={handleCancel} footer={null}>
        <br />
        <div className={"mb-2"}>
          <label>Index: </label>
          <Input
            size={"large"}
            disabled={true}
            value={view.key +1}
            style={{ color: "black" }}
          />
        </div>
        <div className={"mb-2"}>
          <label>Question: </label>
          <Input
            size={"large"}
            disabled={true}
            value={views.query}
            style={{ color: "black" }}
          />
        </div>
        <div className={"mb-2"}>
          <label>Answer: </label>
          <Input.TextArea
            size={"large"}
            disabled={true}
            value={views.response}
            style={{ height: "150px", color: "black" }}
          />
        </div>
        <div className={"mb-2"}>
          <label>Date: </label>
          <Input
            size={"large"}
            disabled={true}
            value={formattedDate}
            style={{ color: "black" }}
          />
        </div>
        {/*<div className={"mb-2"}>
          <label>Type: </label>
          <Input size={"large"} disabled={true} value={views.type} />
        </div>*/}
      </Modal>
    </div>
  );
};

const DisplayQueryDetails = () => {
  const location = useLocation()
  const navigate = useNavigate();
  const { sessionId } = useParams(); // Access the dynamic parameter

  console.log("--------", location?.state?.email)
  const [submitClicked, setSubmitClicked] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [lastDate, setLastDate] = useState(null);

  const [states, setStates] = useState({
    search: "",
    ids: [],
    filterType: defaultFilterType,
    modelOpen: false,
    data: [],
    start_date: "",
    end_date: "",
  });



  const columns = [
    {
      title: "S.NO.",
      dataIndex: "key",
      width: 70,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Session_id",
      dataIndex: "id",
      width: 200,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 600,
    },
    // {
    //   title: "Date",
    //   dataIndex: "dateTime",
    //   width: 220,
    //   render: (text, record) => (
    //     <span>{moment(record.dateTime).format("Do MMM, YYYY, h:mm A")}</span>
    //   ),
    // },
    {
      title: "Details",
      dataIndex: "views",
      width: 80,
      render: (_, record) => {
    
        const handleRedirect = () => {
          navigate(`/query-details/${record.id}`);
        };
    
        return (
          <button onClick={handleRedirect}>
            View Details
          </button>
        );
      },
    }
  ];



  const handleClickList = useCallback(
    (id) => {
      setStates((prevState) => ({
        ...prevState,
        filterType: id,
        modelOpen: !prevState.modelOpen,
      }));
    },
    [states.filterType]
  );

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setStates((prevState) => ({ ...prevState, [name]: value }));
    },
    []
  );

  const fetchData = () => {
    let { filterType, start_date, end_date } = states;
    // let params = {
    //   email: location?.state?.email   //|| localStorage.getItem("email"),
    // };
    // if (searchInput) {
    //   params.session_id = searchInput;
    // }
    // switch (filterType) {
    //   case "all_data":
    //     break;
    //   case "today":
    //     const todayStartDate = moment().startOf("day").utc().format();
    //     const todayEndDate = moment().endOf("day").utc().format();
    //     params.start_date = todayStartDate;
    //     params.end_date = todayEndDate;
    //     break;
    //   case "yesterday":
    //     const yesterdayStartDate = moment()
    //       .subtract(1, "day")
    //       .startOf("day")
    //       .utc()
    //       .format();
    //     const yesterdayEndDate = moment()
    //       .subtract(1, "day")
    //       .endOf("day")
    //       .utc()
    //       .format();
    //     params.start_date = yesterdayStartDate;
    //     params.end_date = yesterdayEndDate;
    //     break;
    //   case "custom_date":
    //     if (start_date)
    //     params.start_date = moment(start_date)
    //       .startOf("day")
    //       .utc()
    //       .format();
    //   if (end_date)
    //     params.end_date = moment(end_date).endOf("day").utc().format();
    //     break;
    //   default:
    //     break;
    // }

    // axios({ url: AppUrl.HISTORY, method: "GET", params: params })
    axios({ url: `https://chatbot.ibism.com/chat/fetch_conversation/${sessionId}`, method: "GET" })
      .then((result) => {
        console.log("---------->",result?.data?.messages)
        // if (
        //   result?.data?.meta?.success &&
        //   Array.isArray(result.data.data) &&
        //   result.data.data.length > 0
        // ) {
        //   const data = result?.data?.data.map((o) => ({
        //     key: o._id,
        //     session_id: o._id,
        //     question: o.input,
        //     dateTime: moment(o.created_at).toDate(),
        //     views: o,
        //   }));

        const options = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true, // 12-hour time format (AM/PM)
        };

        let data = result?.data?.conversations.map((o,i) => ({
          key: i+1,
          // session_id:i+1,
          question: o.query,
          answer: o.response,
          timestamp: new Date(o.timestamp).toLocaleDateString(),
          dateTime: new Date(o.timestamp).toLocaleTimeString([], options ),//moment(o.created_at).toDate(),
          views: o,
        }));

          setStates((prevState) => ({
            ...prevState,
            data: data,
          }));
          setFilteredData(data);
        //   showNotification("success", result?.data?.meta?.message);
        // } else {
        //   setStates((prevState) => ({
        //     ...prevState,
        //     data: [],
        //   }));
        //   setFilteredData([]);
        //   showNotification("success", "Data not available");
        // }
      })
      .catch((err) => showNotification("error", err.message));
  };

  const handleReset = () => {
    setStates({
      search: "",
      ids: [],
      filterType: defaultFilterType,
      data: [],
      start_date: "",
      end_date: "",
    });
    setSearchInput("");
    setInitialLoad(true);
  };
  useEffect(() => {
    if (initialLoad || submitClicked) {
      fetchData();
      setInitialLoad(false);
      setSubmitClicked(false);
    }
  }, [initialLoad, submitClicked]);

  const handleSubmit = () => {
    setSubmitClicked(true);
  };
  return (
    <Row className="listing-cb chat-d_cb bg-white-cb">
      <div style={{ width: "100%", paddingBottom: "50px" }}>
        <Row
          className="mt20 centerData"
          justify="space-between"
          style={{ width: "100%" }}
        >
          <Col align="start">
            <div
              className="back-cb"
              onClick={() => navigate(routes.QUERYDETAILS)}
            >
              <ArrowLeftOutlined />
            </div>
          </Col>
          <Col xs={24} md={12} align="end">
            <Row>
              <Col xs={24} align="end" className="t_c_cb">
                <span>
                  <UserOutlined /> Envigo
                </span>{" "}
                /{" "}
                <span>
                  <MailOutlined /> info@envigo.co.uk
                </span>
              </Col>
            </Row>
          </Col>
        </Row>

        <Col span={24} className="mt20">
          {/* <Table
            columns={columns}
            dataSource={filteredData}
            pagination={false}
            bordered
          /> */}
          <div class="flex flex-col h-screen items-center justify-center antialiased text-gray-800">
            <div class="flex flex-col w-[70%]">
              <div class="flex flex-col flex-auto" className="set-data-o-cb">
                <Row justify="space-between" className="set-data">
                  <Col>Session Id : {sessionId}</Col>
                  <Col>{moment(Date.now()).format("Do MMM, YYYY, h:mm A")}</Col>
                </Row>
              </div>
            </div>
            <div class="flex flex-col w-[70%] h-full overflow-x-hidden">
              <div class="flex flex-col flex-auto h-full p-6">
                <div class="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-l-grey h-full p-4">
                  <div class="flex flex-col h-full overflow-x-auto mb-4">
                    <div class="flex flex-col h-full">
                      <div class="grid grid-cols-12 gap-y-2 mt-[20px]">
                        {filteredData.map((item, index) => {
                          // const currentDate = item?.timestamp;
                          // const isNewDay = currentDate !== lastDate;

                          // // Update lastDate to the current message's date
                          // if (isNewDay) {
                          //   setLastDate(currentDate);
                          // }

                          return (
                            <>
                              {/* {isNewDay && (
                              <div className="date-label text-center text-sm text-gray-500 my-2">
                                {currentDate}
                              </div>
                            )} */}
                              <div class="col-start-1 col-end-8 p-3 rounded-lg">
                                <div class="flex flex-row items-center">
                                  <div class="flex items-center justify-center h-10 w-10 rounded-full bg-white-500 flex-shrink-0">
                                    <img
                                      src="/img/icons/user-icon.svg"
                                      style={{ width: "100%", padding: "7px" }}
                                    />
                                  </div>
                                  <div class="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl min-w-[150px]">
                                    <div>{item?.question}</div>
                                    <div
                                      style={{ top: "-20px" }}
                                      class="absolute text-xs left-0 -mb-5 mr-2 text-gray-500"
                                    >
                                      {item?.dateTime}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-start-6 col-end-13 p-3 rounded-lg">
                                <div class="flex items-center justify-start flex-row-reverse">
                                  <div class="flex items-center justify-center h-10 w-10 rounded-full bg-l-sb100 flex-shrink-0">
                                    <RobotFilled />
                                    {/* <img
                                      src="/img/icons/robot-icon.svg"
                                      style={{ width: "100%", padding: "7px" }}
                                    /> */}
                                  </div>
                                  <div class="relative mr-3 text-sm bg-l-sb-100 py-2 px-4 shadow rounded-xl">
                                    <div>{item?.answer}</div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </div>
    </Row>
  );
};

export default DisplayQueryDetails;
