/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Button,
  Col,
  Divider,
  Input,
  Row,
  Upload,
  message,
  Avatar,
} from "antd";
import {
  LeftOutlined,
  SendOutlined,
  UploadOutlined,
  SaveOutlined,
  QuestionCircleOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import AppUrl from "../config/appUrl";
import { showNotification } from "../utils/commonFunctions";
import GenerateContentContainer from "../openAiChat/components/GenerateContentContainer";
import OpenAiChatItem from "../openAiChat/components/OpenAiChatItem";
import SetMessageModal from "../multipleFileUpload/SetMessageModal";
import Picker from "emoji-picker-react";

const CustomChatBotGo = () => {
  const messageRefs = useRef([]);
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    document.body.classList.add("custom-chat-bot");
    return () => {
      document.body.classList.remove("custom-chat-bot");
    };
  }, []);
  const [state, setState] = useState({
    message: "",
    text: "",
  });
  const [open, setOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [messages, setMessages] = useState([]);
  const [textFromFiles, setTextFromFiles] = useState({});
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [showPicker, setShowPicker] = useState(false);

  console.log("questions", questions);
  const email = localStorage.getItem("email");
  useEffect(() => {
    const userQueries = messages.filter((item) => item.role === "user");
    const otherResponses = messages.filter((item) => item.role !== "user");

    setQuestions(userQueries.map((item) => item.content));
    setAnswers(otherResponses.map((item) => item.content));
  }, [messages]);

  const maxLength = Math.min(questions.length, answers.length);

  const handleSendEmail = async () => {
    try {
      const response = await axios.post(AppUrl.SEND_MAIL, {
        email: email,
        messages: [...Array(maxLength)].map((o, i) => {
          return {
            user: questions[i],
            bot: answers[i],
          };
        }),
      });
      message.success("Successfully mail sent");
    } catch (error) {
      message.error("Failed to send mail");
    }
  };

  useEffect(() => {
    // Your code here
    const email = localStorage.getItem("email");

    axios
      .post(AppUrl.GET_FILE, { email: email })
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data)
          localStorage.setItem("custom_message", res.data?.data?.error_message);
          if (!localStorage.getItem("name")) {
            localStorage.setItem("name", res.data?.data?.name);
          }
          setState((preState) => ({
            ...preState,
            text: res.data?.data?.message,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        showNotification("error", "Failed to fatch file data");
      });
  }, []);
  const saveData = (customMessage) => {
    const email = localStorage.getItem("email");
    setState((preState) => ({
      ...preState,
      loading: true,
    }));
    let payload = { email: email, text: state.text.trim() };
    if (typeof customMessage === "string") {
      payload.customMessage = customMessage;
    }
    axios
      .post(AppUrl.SAVE_FILE, payload)
      .then((res) => {
        if (res.data.success) {
          setState((preState) => ({
            ...preState,
            loading: false,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        showNotification("error", "Failed to save file data");
      });
  };

  const beforeUpload = useCallback((file) => {
    const supportedFileType = ["text/plain"];

    let isAcceptable = supportedFileType.includes(file?.type);
    if (!isAcceptable) {
      showNotification("error", "Please upload .txt file only");
    }
    return isAcceptable || Upload.LIST_IGNORE;
  }, []);

  const updateImageUpload = useCallback(({ file }) => {
    const fileId = file?._id || file?.uid;
    if (file.type === "text/plain") {
      const reader = new FileReader();

      reader.onload = (e) => {
        const fileContent = e.target.result;
        setTextFromFiles((preTextFile) => ({
          ...preTextFile,
          [fileId]: fileContent,
        }));
      };

      reader.readAsText(file);
    }
    setFileList((preState) => [...preState, file]);
  }, []);

  const onRemoveImage = useCallback((file) => {
    const fileId = file?._id || file?.uid;

    setTextFromFiles((preFile) => {
      delete preFile[fileId];
      return preFile;
    });

    setFileList((preState) =>
      preState.filter((p) => (p?._id || p?.uid) !== fileId)
    );
  }, []);

  useEffect(() => {
    let text = Object.values(textFromFiles).join("\n");
    setState((preState) => ({
      ...preState,
      text,
    }));
  }, [textFromFiles, fileList.length]);

  const onGoBack = useCallback(() => navigate(-1), [navigate]);

  const onSend = useCallback(() => {
    let message = state.message.trim();
    const text = state.text.trim();

    if (!message) return;
    if (!text) return;

    const newMessages = messages;
    newMessages.push({
      role: "user",
      content: message,
    });
    const custom_message = localStorage.getItem("custom_message");

    const payload = {
      question: message,
      text: text,
      email: localStorage.getItem("email"),
      customMessage: custom_message,
    };

    newMessages.push({
      role: "assistant",
      content: "typing...",
      loading: true,
    });

    setMessages(newMessages);
    setState((prevState) => ({ ...prevState, message: "", loading: true }));

    const lastMessageIndex = messages.length - 1;
    setTimeout(
      () =>
        messageRefs?.current?.[lastMessageIndex]?.scrollIntoView?.({
          behavior: "smooth",
        }),
      100
    );

    axios({
      method: "POST",
      url: `${AppUrl.FILES}custom`,
      data: payload,

      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjYyZTIzYzA1ZDkzZDFjNmMzZDRiY2MwYSIsImVtYWlsIjoic29ub28uZ3VwdGFAaWJpc20uY29tIiwicm9sZV9pZCI6ImFkbWluIiwibmFtZSI6bnVsbH0sImlhdCI6MTY4NzM0ODIwNywiZXhwIjoxNjg3OTUzMDA3fQ.Po1HQFCga0pzAAj6aIkFL8nBwVTkbXR2hwQdEhATHLM",
      },
    })
      .then((response) => {
        const resData = response.data;
        // console.log(resData);

        if (!resData?.text) {
          setMessages((prevMessages) => {
            const lastMesssage = prevMessages.pop();
            console.log("remove message: ", lastMesssage);

            return [...prevMessages];
          });
        } else {
          const newMessage = {};
          let content = resData?.text;
          while (content.startsWith("\n")) {
            content = content.replace("\n", "");
          }
          newMessage.content = content;

          setTimeout(
            () =>
              messageRefs?.current?.[lastMessageIndex + 1]?.scrollIntoView?.({
                behavior: "smooth",
              }),
            100
          );

          setMessages((prevMessages) => {
            prevMessages.pop();

            return [...prevMessages, newMessage];
          });
        }

        setState((prevState) => ({ ...prevState, loading: false }));
      })
      .catch((err) => {
        console.log("chat err: ", err);
        showNotification("error", "Something went wrong");
        setMessages((prevMessages) => {
          const lastMesssage = prevMessages.pop();
          console.log("remove message: ", lastMesssage);

          return [...prevMessages];
        });
        setState((prevState) => ({ ...prevState, loading: false }));
      });
  }, [state.message, state.text, messages]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((prevState) => {
        prevState[name] = value;
        return { ...prevState };
      });
    },
    []
  );

  const onEmojiClick = (event, emojiObject) => {
    setState((prevState) => {
      const updatedMessage = prevState.message + emojiObject.emoji;
      return { ...prevState, message: updatedMessage };
    });
    setShowPicker(false);
  };
  
  return (
    <div>
      <GenerateContentContainer containerClassName="open-ai-chat">
        <Row className="content-containe">
          <Col xs={24} sm={24} md={24}>
            <div className="center-frame">
              <div className="tab-frame-cbs">
                <div className="tab-inner-frame-cb">
                  <Col
                    className=""
                    style={{
                      height: "420px",
                      overflowY: "auto",
                      paddingRight: "5px",
                      paddingTop: "10px",
                    }}
                  >
                    {/* chat container start */}
                    <Col className="chat-container h-4/6">
                      <Col className="message-list-container">
                        <Row className="chat-item-container">
                          <Col>
                            <Avatar
                              size={40}
                              shape={"circle"}
                              style={{ backgroundColor: "rgb(255, 161, 108)" }}
                            >
                              {"Bot"}
                            </Avatar>
                          </Col>

                          <Col className="chat-details-container">
                            <Row
                              className="user-details"
                              align="middle"
                              justify="space-between"
                            >
                              {/* <span className="name">{createdBy.name ?? "Bot"}</span> */}
                              <span className="name">{"GO Bermondsey"}</span>
                              <span>
                                Hello 👋, I'm GO Bermondsey bot, ready to help!
                                😊 Feel free to share your inquiry with me.
                              </span>
                            </Row>
                          </Col>
                        </Row>
                        {/* <div className="defaultMsg">
                          <p>
                            Hello 👋, I'm the Fit2Trip bot, ready to help! 😊
                          </p>
                          <p>Feel free to share your inquiry with me. </p>
                        </div> */}
                        {messages?.map?.((message, i) => (
                          <div
                            key={i}
                            ref={(mRef) => (messageRefs.current[i] = mRef)}
                          >
                            <OpenAiChatItem key={i} message={message} />
                            <Row justify="end" align="">
                              {message.content ===
                                "Sorry we could not find detail about your query. Please contact our support team at help@oben.com | +91 1232123212." && (
                                <Col
                                  style={{
                                    marginRight: "20px",
                                    marginTop: "-110px",
                                  }}
                                >
                                  {/* <Button>
                                    <MailOutlined onClick={handleSendEmail} />
                                  </Button> */}
                                </Col>
                              )}
                            </Row>
                          </div>
                        ))}

                        {/* {!messages.length && (
                          <Row
                            className="h-full"
                            align="middle"
                            justify="center"
                          >
                            <div className="defaultMsg">
                              <p>
                                Hello 👋, I'm the Fit2Trip bot, ready to help!
                                😊
                              </p>
                              <p>Feel free to share your inquiry with me. </p>
                            </div>
                          </Row>
                        )} */}
                      </Col>
                    </Col>
                    {/* chat container end */}
                  </Col>

                  <Col className="mt20">
                    {/* chat input container end */}
                    <Row align="middle" wrap={false}>
                      <Col className="grow">
                        <div className="input-rel-cb">
                          <Input
                            className="chat-input"
                            value={state.message}
                            placeholder="Enter your message"
                            onChange={handleChange("message")}
                            autoSize={{ minRows: 1, maxRows: 4 }}
                            onPressEnter={(e) => {
                              if (e.key === "Enter" && !e.shiftKey) {
                                onSend();
                              }
                            }}
                            disabled={state.loading}
                            size="large"
                          />

                          <div className="emoji-cb">
                            <img
                              className="emoji-icon"
                              src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                              onClick={() => setShowPicker((val) => !val)}
                            />

                            <Button
                              className="text-2xl btn-color"
                              type="text"
                              onClick={onSend}
                              disabled={!state.message.trim()}
                              loading={state.loading}
                              size="large"
                              icon={<SendOutlined className="text-3xl" />}
                            />
                          </div>
                        </div>
                      </Col>

                      {showPicker && (
                        <div className="popup-emoji-cb">
                          <Picker
                            pickerStyle={{ width: "100%" }}
                            onEmojiClick={onEmojiClick}
                          />
                        </div>
                      )}
                    </Row>
                    {/* chat input container end */}
                  </Col>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </GenerateContentContainer>
      <SetMessageModal open={open} handleModal={setOpen} save={saveData} />
    </div>
  );
};

export default React.memo(CustomChatBotGo);
