import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Input, Row, Col } from "antd";

import CustomModal from "../components/CustomModal";

const StartNewWebScrapper = (props) => {
  const { visible, handleModal, onUserInvite } = props;
  const [state, setState] = useState({});
  const [formError, setFormError] = useState({
        first_name: null,
        last_name: null,
        email: null,
      });

  const hasError = useCallback(() => {
    let { pageName, url } = state;
    let error = {};

    pageName = pageName?.trim();
    if (!pageName) {
      error.pageName = "Please enter page name";
    }
    if (!url) {
      error.url = "Please enter url";
    }


    setFormError(error);
    return !!Object.keys(error).length;
  }, [state]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event?.target?.checked;

      setFormError({});
      setState((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );
    let { pageName, url } = state;

 const onOk = useCallback(() => {
    if (!hasError()) {
        setState((pre) => ({
            ...pre,
            loading: true,
        }))
      let newUser = {
        pageName: pageName,
        url: url,
      };
        onUserInvite(newUser)
        setState({})
    }
  }, [pageName, hasError, url]);



  const renderFooter = useMemo(() => {
    return (
      <Row className="p10">
        <Button
          className="new_button"
          type="primary"
                size="large"
                 onClick={onOk}
          loading={state.loading}

        >
          Add
        </Button>
        <Button
          className="new_button"
          type="primary-outline"
          onClick={handleModal}
          size="large"
        >
          Cancel
        </Button>
      </Row>
    );
  }, [handleModal, onOk]);

  return (
    <CustomModal
      visible={visible}
      title={"Start New Web Scrapper"}
      okButtonText={"Submit"}
      onCancel={props?.handleModal}
      footer={renderFooter}
    >
      <div>
        <Col className="mb10">
          <Col className="mb5">
            <label className="label">Page Name</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col>
            <Input
              size="large"
              type="text"
              value={pageName}
              placeholder=""
              onChange={handleChange("pageName")}
            />
            <Row className="error mt5">{formError?.pageName}</Row>
          </Col>
        </Col>

        <Col className="mb10">
          <Col className="mb5">
                      <label className="label">URL</label>
                      <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col>
            <Input
              size="large"
              type="text"
              value={url}
              placeholder=""
              onChange={handleChange("url")}
                      />
                      <Row className="error mt5">{formError?.url}</Row>
          </Col>
        </Col>

        
      </div>
    </CustomModal>
  );
};

export default StartNewWebScrapper;
