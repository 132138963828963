/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ReactTyped } from "react-typed";
import {
  Button,
  Col,
  Divider,
  Input,
  Row,
  Upload,
  message,
  Avatar,
} from "antd";
import {
  LeftOutlined,
  SendOutlined,
  UploadOutlined,
  SaveOutlined,
  QuestionCircleOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import AppUrl from "../config/appUrl";
import { showNotification } from "../utils/commonFunctions";
import GenerateContentContainer from "../openAiChat/components/GenerateContentContainer";
import OpenAiChatItem from "../openAiChat/components/OpenAiChatItem";
import SetMessageModal from "../multipleFileUpload/SetMessageModal";
import Picker from "emoji-picker-react";
import ChatFormBox from "./formbox";
import citiustechLogo from "../images/citiustech-logo.svg";


  const msgData = [
    {
      id: 1,
      text: "Hello! I noticed you've been reading about our Quality and Validation services.",
      tm: 0,
    },
    {
      id: 2,
      text: "I think you might find our Performance Testing services around Quality and Validation and even the latest guidebook on how GenAI can improve quality assurance processes for Healthcare solutions.",
      tm: 6000,
    },
    {
      id: 3,
      text: "Also, you may find the perspective around industry-first GenAI Quality & Trust Solution for Healthcare interesting.",
      tm: 9000,
    },

    {
      id: 4,
      text: "Here's it is for you to read through.",
      tm: 9000,
    },

    {
      id: 5,
      text: "Would you like to be the first to know about new Thought Leadership content around Quality and Validation in Healthcare? Do share your email address with us and get notified as soon as it's available.",
      tm: 20000,
    },

    {
      id: 6,
      text: "Additionally, would you like to stay up-to-date on the latest news and insights on Healthcare Technology? Our monthly email newsletter is packed with valuable information and expert perspectives. Share your email address with us and get access to our expert resources and insights.",
      tm: 25000,
    },
  ];
  
const thankYouMsg = {
  title: `Thank you!`,
  msg: `Thank you for subscribing! 🎉 We'll keep you updated with the latest details and our newsletter. Stay tuned!`,
};

const notInterested = {
  text:`Looking forward to connecting with you in the future. Feel free to reach out to us, and one of our executives will be happy to assist you.`,
  mail:`📩 For general information: info@citiustech.com`,
};


const defaultMsg = {
  msg_1: `It seems to me that you are currently engaged in browsing and reading out the Thought
Leadership content.`,
  msg_2: `You are welcome to resume the conversation after a while. You can find me towards the bottom right position of the screen.`,
};



const CustomChatBotCitiustech = () => {
  const messageRefs = useRef([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [showForm, setShowForm] = useState(false);
  const [showThankMsg, setShowThankMsg] = useState(false);

  useEffect(() => {
    document.body.classList.add("custom-chat-bot");
    return () => {
      document.body.classList.remove("custom-chat-bot");
    };
  }, []);
  const [state, setState] = useState({
    message: "",
    text: "",
  });
  const [open, setOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [messages, setMessages] = useState([]);
  const [textFromFiles, setTextFromFiles] = useState({});
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [showPicker, setShowPicker] = useState(false);
  const [notInterest, setNotInterest] = useState(false);
  const [defaultMsgData, setDefaultMsgData] = useState(false);
   const containerRef = useRef(null);
  const containerRefForm = useRef(null);
  const containerRefnotInterest = useRef(null);
  const containerRefdefaultMsg = useRef(null);
  
  ;
  const [currentMessages, setCurrentMessages] = useState([]);
  const hasRunRef = useRef(false);
  const [c, setC] = useState(0)
 
  useEffect(() => {
    
    if (hasRunRef.current) return;
    hasRunRef.current = true;
    msgData.forEach((msg) => {
      console.log(msg?.text.length);
      setTimeout(() => {
        setCurrentMessages((prevMessages) => [...prevMessages, msg]);
      }, msg.tm + 12000);
    });
  }, []);


  useEffect(() => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef?.current?.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  }, [currentMessages]);

  useEffect(() => {
    setTimeout(() => {
      if (containerRefForm.current) {
        containerRefForm?.current?.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  }, [c]);

  useEffect(() => {
    setTimeout(() => {
      if (containerRefnotInterest.current) {
        containerRefnotInterest?.current?.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  }, [c]);


  useEffect(() => {
    setTimeout(() => {
      setDefaultMsgData(true);
      if (containerRefdefaultMsg.current) {
        containerRefdefaultMsg?.current?.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 55000 + 12000);
  }, []);

  //console.log("questions", questions);
  const email = localStorage.getItem("email");
  useEffect(() => {
    const userQueries = messages.filter((item) => item.role === "user");
    const otherResponses = messages.filter((item) => item.role !== "user");

    setQuestions(userQueries.map((item) => item.content));
    setAnswers(otherResponses.map((item) => item.content));
  }, [messages]);

  const maxLength = Math.min(questions.length, answers.length);



  const handleSendEmail = async () => {
    try {
      const response = await axios.post(AppUrl.SEND_MAIL, {
        email: email,
        messages: [...Array(maxLength)].map((o, i) => {
          return {
            user: questions[i],
            bot: answers[i],
          };
        }),
      });
      message.success("Successfully mail sent");
    } catch (error) {
      message.error("Failed to send mail");
    }
  };

  useEffect(() => {
    // Your code here
    const email = localStorage.getItem("email");

    axios
      .post(AppUrl.GET_FILE, { email: email })
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data)
          localStorage.setItem("custom_message", res.data?.data?.error_message);
          if (!localStorage.getItem("name")) {
            localStorage.setItem("name", res.data?.data?.name);
          }
          setState((preState) => ({
            ...preState,
            text: res.data?.data?.message,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        showNotification("error", "Failed to fatch file data");
      });
  }, []);
  const saveData = (customMessage) => {
    const email = localStorage.getItem("email");
    setState((preState) => ({
      ...preState,
      loading: true,
    }));
    let payload = { email: email, text: state.text.trim() };
    if (typeof customMessage === "string") {
      payload.customMessage = customMessage;
    }
    axios
      .post(AppUrl.SAVE_FILE, payload)
      .then((res) => {
        if (res.data.success) {
          setState((preState) => ({
            ...preState,
            loading: false,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        showNotification("error", "Failed to save file data");
      });
  };

  const beforeUpload = useCallback((file) => {
    const supportedFileType = ["text/plain"];

    let isAcceptable = supportedFileType.includes(file?.type);
    if (!isAcceptable) {
      showNotification("error", "Please upload .txt file only");
    }
    return isAcceptable || Upload.LIST_IGNORE;
  }, []);

  const updateImageUpload = useCallback(({ file }) => {
    const fileId = file?._id || file?.uid;
    if (file.type === "text/plain") {
      const reader = new FileReader();

      reader.onload = (e) => {
        const fileContent = e.target.result;
        setTextFromFiles((preTextFile) => ({
          ...preTextFile,
          [fileId]: fileContent,
        }));
      };

      reader.readAsText(file);
    }
    setFileList((preState) => [...preState, file]);
  }, []);

  const onRemoveImage = useCallback((file) => {
    const fileId = file?._id || file?.uid;

    setTextFromFiles((preFile) => {
      delete preFile[fileId];
      return preFile;
    });

    setFileList((preState) =>
      preState.filter((p) => (p?._id || p?.uid) !== fileId)
    );
  }, []);

  useEffect(() => {
    let text = Object.values(textFromFiles).join("\n");
    setState((preState) => ({
      ...preState,
      text,
    }));
  }, [textFromFiles, fileList.length]);

  const onGoBack = useCallback(() => navigate(-1), [navigate]);

  const notInterestedOnClick = () => {
    const newMessages = messages;
    newMessages.push({
      role: "assistant",
      content:
        "Looking forward to connecting with you in the future. Feel free to reach out to us, and one of our executives will be happy to assist you.\n" +
        "\n" +
        "📩 For general information: info@citiustech.com",
    });
    setMessages(newMessages);
    setState((prevState) => ({ ...prevState, message: "", loading: false }));

    const lastMessageIndex = messages.length - 1;
    setTimeout(
      () =>
        messageRefs?.current?.[lastMessageIndex]?.scrollIntoView?.({
          behavior: "smooth",
        }),
      100
    );
  };

  const onSend = useCallback(() => {
    let message = state.message.trim() || state?.custom_message?.trim();
    const text = state.text.trim();
    if (!message) return;
    if (!text) return;

    const newMessages = messages;
    newMessages.push({
      role: "user",
      content: message,
    });
    const custom_message = localStorage.getItem("custom_message");

    const payload = {
      question: message,
      text: text,
      email: localStorage.getItem("email"),
      customMessage: custom_message,
    };

    newMessages.push({
      role: "assistant",
      content: "typing...",
      loading: true,
    });

    setMessages(newMessages);
    setState((prevState) => ({ ...prevState, message: "", loading: true }));

    const lastMessageIndex = messages.length - 1;
    setTimeout(
      () =>
        messageRefs?.current?.[lastMessageIndex]?.scrollIntoView?.({
          behavior: "smooth",
        }),
      100
    );

    axios({
      method: "POST",
      url: `${AppUrl.FILES}custom`,
      data: payload,

      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjYyZTIzYzA1ZDkzZDFjNmMzZDRiY2MwYSIsImVtYWlsIjoic29ub28uZ3VwdGFAaWJpc20uY29tIiwicm9sZV9pZCI6ImFkbWluIiwibmFtZSI6bnVsbH0sImlhdCI6MTY4NzM0ODIwNywiZXhwIjoxNjg3OTUzMDA3fQ.Po1HQFCga0pzAAj6aIkFL8nBwVTkbXR2hwQdEhATHLM",
      },
    })
      .then((response) => {
        const resData = response.data;
        // console.log(resData);

        if (!resData?.text) {
          setMessages((prevMessages) => {
            const lastMesssage = prevMessages.pop();
            console.log("remove message: ", lastMesssage);

            return [...prevMessages];
          });
        } else {
          const newMessage = {};
          let content = resData?.text;
          while (content.startsWith("\n")) {
            content = content.replace("\n", "");
          }
          newMessage.content = content;

          setTimeout(
            () =>
              messageRefs?.current?.[lastMessageIndex + 1]?.scrollIntoView?.({
                behavior: "smooth",
              }),
            100
          );

          setMessages((prevMessages) => {
            prevMessages.pop();

            return [...prevMessages, newMessage];
          });
        }

        setState((prevState) => ({ ...prevState, loading: false }));
      })
      .catch((err) => {
        console.log("chat err: ", err);
        showNotification("error", "Something went wrong");
        setMessages((prevMessages) => {
          const lastMesssage = prevMessages.pop();
          console.log("remove message: ", lastMesssage);

          return [...prevMessages];
        });
        setState((prevState) => ({ ...prevState, loading: false }));
      });
  }, [state.message, state.text, messages, state?.custom_message]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((prevState) => {
        prevState[name] = value;
        return { ...prevState };
      });
    },
    []
  );

  const handleCustomButtonClick = (value) => {
    setState((prevState) => {
      prevState["custom_message"] = value;
      return { ...prevState };
    });
  };

  useEffect(() => {
    if (state?.custom_message?.trim().length > 0) {
      onSend();
    }
  }, [state?.custom_message]);

  const onEmojiClick = (event, emojiObject) => {
    setState((prevState) => {
      const updatedMessage = prevState.message + emojiObject.emoji;
      return { ...prevState, message: updatedMessage };
    });
    setShowPicker(false);
  };

  return (
    <div>
      <GenerateContentContainer containerClassName="open-ai-chat">
        <Row className="content-containe">
          <Col xs={24} sm={24} md={24}>
            <div className="center-frame">
              <div className="tab-frame-cbs">
                <div className="tab-inner-frame-cb">
                  <Col
                    className="scroll-d-cb"
                    style={{
                      height: "420px",
                      overflowY: "auto",
                      paddingRight: "5px",
                      paddingTop: "10px",
                    }}
                  >
                    {/* chat container start */}
                    <Col className="chat-container h-4/6">
                      <Col className="message-list-container">
                        {currentMessages.map((msg, i) => (
                          <>
                            <Row className="chat-item-container" key={i}>
                              <Col style={{ width: "34px" }}>
                                {(i === 0 || i === 2 || i === 4) && (
                                  <img src={citiustechLogo} alt="CitiusTech" />
                                )}
                              </Col>

                              <Col className="chat-details-container">
                                <Row
                                  className="user-details"
                                  align="middle"
                                  justify="space-between"
                                >
                                  {(i === 0 || i === 2 || i === 4) && (
                                    <span className="name">{"Bot"}</span>
                                  )}

                                  <span className="full_cb" ref={containerRef}>
                                    {/* <ReactTyped
                                      className="full_cb"
                                      ref={containerRef}
                                      key={i}
                                      strings={[msg.text]}
                                      typeSpeed={500}
                                    /> */}
                                    {msg.text}
                                  </span>

                                  {msg?.id === 4 && (
                                    <div className="chat-c-cb-m">
                                      <a
                                        href="https://www.citiustech.com/services/quality-and-validation/performance-testing"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="custom-button-container"
                                      >
                                        Performance Testing
                                      </a>

                                      <a
                                        href="https://www.citiustech.com/re-imagen-ai/genai-quality-assured"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="custom-button-container"
                                      >
                                        Explore the Guidebook
                                      </a>

                                      <a
                                        href="https://www.citiustech.com/citius-vision/guidebook/improve-quality-assurance-processes-with-genai"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="custom-button-container"
                                      >
                                        Perspective GenAI Quality Assured
                                      </a>
                                    </div>
                                  )}

                                  {msg?.id === 5 && (
                                    <div className="chat-c-cb-m">
                                      <i
                                        onClick={() => {
                                          setShowForm(true);
                                          setNotInterest(false);
                                          setC(c + 1);
                                        }}
                                        className={"custom-button-container"}
                                      >
                                        Get Exclusive Insights
                                      </i>
                                    </div>
                                  )}

                                  {msg?.id === 6 && (
                                    <div className="chat-c-cb-m">
                                      <i
                                        onClick={() => {
                                          setShowForm(true);
                                          setNotInterest(false);
                                          setC(c + 1);
                                        }}
                                        className={"custom-button-container"}
                                      >
                                        Subscribe to Insights Newsletter
                                      </i>

                                      <i
                                        onClick={() => {
                                          setShowForm(false);
                                          setNotInterest(true);
                                          setC(c + 1);
                                        }}
                                        className={
                                          "custom-button-container blue_btn"
                                        }
                                      >
                                        Not Interested
                                      </i>
                                    </div>
                                  )}

                                  {/* {currentMessages?.id !== 6 && (
                                <span>Typing...</span>
                              )} */}
                                </Row>
                              </Col>
                            </Row>
                          </>
                        ))}

                        {showForm && (
                          <div ref={containerRefForm}>
                            <ChatFormBox
                              setShowForm={setShowForm}
                              setShowThankMsg={setShowThankMsg}
                            />
                          </div>
                        )}

                        {!showForm && !notInterest && showThankMsg && (
                          <Row className="chat-item-container">
                            <Col style={{ width: "34px" }}>
                              {/*  <img src={citiustechLogo} alt="CitiusTech" /> */}
                            </Col>

                            <Col className="chat-details-container">
                              <Row
                                className="user-details"
                                align="middle"
                                justify="space-between"
                              >
                                <Col className="chat-details-data-form">
                                  <Col>
                                    <h4>{thankYouMsg?.title}</h4>
                                    <span className="full_cb">
                                      {thankYouMsg?.msg}
                                    </span>
                                  </Col>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )}

                        {notInterest && (
                          <Row
                            className="chat-item-container"
                            ref={containerRefnotInterest}
                          >
                            <Col style={{ width: "34px" }}>
                              <img src={citiustechLogo} alt="CitiusTech" />
                            </Col>

                            <Col className="chat-details-container">
                              <Row
                                className="user-details"
                                align="middle"
                                justify="space-between"
                              >
                                <span className="name">{"Bot"}</span>
                                <span className="full_cb">
                                  {notInterested?.text}
                                </span>
                                <span className="full_cb">
                                  {notInterested?.mail}
                                </span>
                              </Row>
                            </Col>
                          </Row>
                        )}

                        {defaultMsgData && (
                          <Row
                            className="chat-item-container"
                            ref={containerRefdefaultMsg}
                          >
                            <Col style={{ width: "34px" }}>
                              <img src={citiustechLogo} alt="CitiusTech" />
                            </Col>

                            <Col className="chat-details-container">
                              <Row
                                className="user-details"
                                align="middle"
                                justify="space-between"
                              >
                                <span className="name">{"Bot"}</span>
                                <span className="full_cb">
                                  {defaultMsg?.msg_1}
                                </span>
                                <span className="full_cb">
                                  {defaultMsg?.msg_2}
                                </span>
                              </Row>
                            </Col>
                          </Row>
                        )}

                        {messages?.map?.((message, i) => (
                          <div
                            key={i}
                            ref={(mRef) => (messageRefs.current[i] = mRef)}
                          >
                            <OpenAiChatItem key={i} message={message} />
                            <Row justify="end" align="">
                              {message.content ===
                                "Sorry we could not find detail about your query. Please contact our support team at help@oben.com | +91 1232123212." && (
                                <Col
                                  style={{
                                    marginRight: "20px",
                                    marginTop: "-110px",
                                  }}
                                ></Col>
                              )}
                            </Row>
                          </div>
                        ))}

                        {/* {!messages.length && (
                          <Row
                            className="h-full"
                            align="middle"
                            justify="center"
                          >
                            <div className="defaultMsg">
                              <p>
                                Hello 👋, I'm the Fit2Trip bot, ready to help!
                                😊
                              </p>
                              <p>Feel free to share your inquiry with me. </p>
                            </div>
                          </Row>
                        )} */}
                      </Col>
                    </Col>
                    {/* chat container end */}
                  </Col>

                  <Col className="mt20">
                    {/* chat input container end */}
                    <Row align="middle" wrap={false}>
                      <Col className="grow">
                        <div className="input-rel-cb">
                          <Input
                            className="chat-input"
                            value={state.message}
                            placeholder="Enter your message"
                            onChange={handleChange("message")}
                            autosize={{ minRows: 1, maxRows: 4 }}
                            onPressEnter={(e) => {
                              if (e.key === "Enter" && !e.shiftKey) {
                                onSend();
                              }
                            }}
                            disabled={state.loading}
                            size="large"
                          />

                          <div className="emoji-cb">
                            <img
                              className="emoji-icon"
                              src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                              onClick={() => setShowPicker((val) => !val)}
                            />

                            <Button
                              className="text-2xl btn-color"
                              type="text"
                              onClick={onSend}
                              disabled={!state.message.trim()}
                              loading={state.loading}
                              size="large"
                              icon={<SendOutlined className="text-3xl" />}
                            />
                          </div>
                        </div>
                      </Col>

                      {showPicker && (
                        <div className="popup-emoji-cb">
                          <Picker
                            pickerStyle={{ width: "100%" }}
                            onEmojiClick={onEmojiClick}
                          />
                        </div>
                      )}
                    </Row>
                    {/* chat input container end */}
                  </Col>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </GenerateContentContainer>
      <SetMessageModal open={open} handleModal={setOpen} save={saveData} />
    </div>
  );
};

export default React.memo(CustomChatBotCitiustech);
